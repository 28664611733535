import React from "react";
import { graphql } from "gatsby";
import QRCode from "qrcode.react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../../components/layout";
import * as styles from "./stores.module.scss";

const AppStoreQrPage = () => {
  const { t } = useTranslation();
  let url: string;
  if (typeof window !== "undefined") {
    url = `${window.location.hostname}/app/store`;
  }

  return (
    <Layout>
      <div className={styles.stores}>
        <h1>{t("app.stores.title")}</h1>
        <QRCode value={url} size={256} />
      </div>
    </Layout>
  );
};

export default AppStoreQrPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
